class Utils {
  static json2csv (json) {
    var header = Object.keys(json[0]).join('\t') + '\n'

    var body = json.map(d => {
      return Object.keys(d).map(key => {
        return d[key]
      }).join('\t')
    }).join('\n')

    return header + body
  }
  static execCopy (string) {
    var temp = document.createElement('div')

    temp.appendChild(document.createElement('pre')).textContent = string

    var s = temp.style
    s.position = 'fixed'
    s.left = '-100%'

    document.body.appendChild(temp)
    document.getSelection().selectAllChildren(temp)

    var result = document.execCommand('copy')

    document.body.removeChild(temp)
    // true なら実行できている falseなら失敗か対応していないか
    return result
  }
}
export default Utils
