<template>
  <div>
    <div class="main-content mt-2">
      <div class="header">
        <b-alert variant="info" :show="message != null">{{message}}</b-alert>
        <b-alert variant="danger" :show="error != null">{{error}}</b-alert>
      </div>
      <b-container fluid>
        <b-row class="p-2 border-bottom border-info">
          <b-col>
            <h4 class="text-info font-italic">
              <b-icon-person-fill />
              <strong>同意リスト</strong>
              <b-btn variant="info" class="ml-5" :disabled="loading" @click="reloadItems">
                <b-icon icon="arrow-repeat"></b-icon>再読込
              </b-btn>
            </h4>
          </b-col>
          <b-col class="text-right">
            <b-btn variant="outline-success" @click="download">
              <b-icon icon="cloud-download"></b-icon>CSV
            </b-btn>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" class="mt-3">
            <b-form-group
              label-for="filter-input"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="table.filter"
                  type="search"
                  placeholder="検索"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!table.filter" @click="table.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" class="mt-3">
            <b-form-select
              v-model="table.filteringMeeting"
              :options="meetings"
              class="mb-3"
              @change="onFilterChanged">
              <template #first>
                <b-form-select-option :value="null">-- 説明会を指定して絞込む --</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-2 border-bottom border-info">
          <b-col>
            <b-form-group label="項目の非表示">
              <b-form-checkbox-group
                switches
                v-model="table.invisibleItems"
                :options="table.invisibleItemOptions"
              ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          :items="filteredUsers"
          :fields="visibleTableFields"
          :filter="table.filter"
          :filter-included-fields="table.filterOn"
          :sort-by.sync="table.sortBy"
          :sort-desc.sync="table.sortDesc"
          :sort-direction="table.sortDirection"
          :tbody-tr-class="tableRowClass"
          striped
          bordered
          hover
          stacked="md"
          show-empty
          no-border-collapse
          small
          responsive
        >
          <template #cell(consentNo)="row">
            <strong>{{ row.value }}</strong>
          </template>
          <template #cell(groupId)="row">
            <div v-if="isEditing(row.item, 'groupId')">
              <b-form-select
                v-model="editForm.groupId"
                :options="editForm.groupOptions"
                @change="onEdit"></b-form-select>
            </div>
            <div v-else>
              <div @click.prevent="onStartEditing(row.item, 'groupId')">
                <h5><b-badge :variant="groupVariant(row.value)">{{ formatGroup(row.value) }}</b-badge></h5>
              </div>
            </div>
          </template>
          <template #cell(userNo)="row">
            <div v-if="isEditing(row.item, 'userNo')">
              <b-form-input
                type="text"
                v-model="editForm.userNo"
                trim
                @blur="onEdit"></b-form-input>
            </div>
            <div v-else>
              <div v-if="!(!row.value)">
                <div @click.prevent="onStartEditing(row.item, 'userNo')">
                  <span>{{ row.value }}</span>
                </div>
              </div>
              <div @click.prevent="onStartEditing(row.item, 'userNo')" v-else>
                <b-icon icon="pencil-square" scale="2" class="pt-1"></b-icon>
              </div>
            </div>
          </template>
          <template #cell(meetingId)="row">
            <div v-if="isEditing(row.item, 'meetingId')">
              <b-form-input
                type="text"
                v-model="editForm.meetingId"
                trim
                @blur="onEdit"></b-form-input>
            </div>
            <div v-else>
              <div v-if="!(!row.value)">
                <div @click.prevent="onStartEditing(row.item, 'meetingId')">
                  <span>{{ row.value }}</span>
                </div>
              </div>
              <div @click.prevent="onStartEditing(row.item, 'meetingId')" v-else>
                <b-icon icon="pencil-square" scale="2" class="pt-1"></b-icon>
              </div>
            </div>
          </template>
          <template #cell(gMeetId)="row">
            <div v-if="isEditing(row.item, 'gMeetId')">
              <b-form-input
                type="text"
                v-model="editForm.gMeetId"
                trim
                @blur="onEdit"></b-form-input>
            </div>
            <div v-else>
              <div v-if="!(!row.value)">
                <div @click.prevent="onStartEditing(row.item, 'gMeetId')">
                  <span>{{ row.value }}</span>
                </div>
              </div>
              <div @click.prevent="onStartEditing(row.item, 'gMeetId')" v-else>
                <b-icon icon="pencil-square" scale="2" class="pt-1"></b-icon>
              </div>
            </div>
          </template>
          <template #cell(minchalleName)="row">
            <div v-if="isEditing(row.item, 'minchalleName')">
              <b-form-input
                type="text"
                v-model="editForm.minchalleName"
                trim
                @blur="onEdit"></b-form-input>
            </div>
            <div v-else>
              <div v-if="!(!row.value)">
                <div @click.prevent="onStartEditing(row.item, 'minchalleName')">
                  <span>{{ row.value }}</span>
                </div>
              </div>
              <div @click.prevent="onStartEditing(row.item, 'minchalleName')" v-else>
                <b-icon icon="pencil-square" scale="2" class="pt-1"></b-icon>
              </div>
            </div>
          </template>
          <template #cell(smartPhoneType)="row">
            <div v-if="isEditing(row.item, 'smartPhoneType')">
              <b-form-select
                v-model="editForm.smartPhoneType"
                :options="editForm.smartPhoneTypeOptions"
                @change="onEdit"></b-form-select>
            </div>
            <div @click.prevent="onStartEditing(row.item, 'smartPhoneType')" v-else>
              <h5>
                <b-badge variant="danger" v-if="row.value === 'iPhone'">iPhone</b-badge>
                <b-badge variant="info" v-else-if="row.value === 'Android'">Android</b-badge>
                <b-badge v-else>-</b-badge>
              </h5>
            </div>
          </template>
          <template #cell(minchalleApp)="row">
            <div @click.prevent="onCheck(row.item, 'minchalleApp', false)" v-if="row.value">
              <b-icon icon="check-square-fill" scale="2" class="pt-1"></b-icon>
            </div>
            <div @click.prevent="onCheck(row.item, 'minchalleApp', true)" v-else>
              <b-icon icon="square" scale="2" class="pt-1"></b-icon>
            </div>
          </template>
          <template #cell(healthFitApp)="row">
            <div @click.prevent="onCheck(row.item, 'healthFitApp', false)" v-if="row.value">
              <b-icon icon="check-square-fill" scale="2" class="pt-1"></b-icon>
            </div>
            <div @click.prevent="onCheck(row.item, 'healthFitApp', true)" v-else>
              <b-icon icon="square" scale="2" class="pt-1"></b-icon>
            </div>
          </template>
          <template #cell(status)="row">
            <div @click="openStatusEditModal(row.item)">
              <b-badge :variant="statusVariant(row.value)" v-if="row.value === 'FIXED'">
                <span class="text-white">{{ formatStatus(row.value) }}</span>
              </b-badge>
              <b-badge :variant="statusVariant(row.value)" v-else>{{ formatStatus(row.value) }}</b-badge>
            </div>
          </template>
          <template #cell(createdAt)="row">
            {{ formatDate(row.value) }}
          </template>
          <template #cell(lastModifiedAt)="row">
            {{ formatDate(row.value) }}
          </template>
        </b-table>
      </b-container>
    </div>
    <div>
      <b-modal ref="statusEditModal" title="状態変更"
        centered
        header-bg-variant="info"
        header-text-variant="light"
        @ok.prevent="onStatusEdit">
        <div>
          <p class="h5">
            現在：{{ formatStatus(editing.status) }}
            <b-icon icon="triangle-fill" rotate="90" class="mx-2"></b-icon>
            <b-form-select v-model="editForm.status" :options="editForm.statusOptions" @change="onStatusEdit"></b-form-select>
          </p>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { Logger } from 'aws-amplify'
import moment from 'moment-timezone'
import utils from '@/utils'

const logger = new Logger('Users')
const STATUS = {
  YET: { name: '未定', variant: 'secondary' },
  VALID: { name: '有効', variant: 'success' },
  FIXED: { name: '完了', variant: 'info' },
  INVALID: { name: '無効', variant: 'secondary' },
  DELETED: { name: '削除', variant: 'secondary' }
}
const GROUPS = {
  YET: { name: '未定', variant: 'secondary' },
  MINCHALLE: { name:'A:習慣化アプリ', variant: 'danger' },
  GFIT: { name: 'B:歩数アプリ', variant: 'info' }
}

export default {
  name: 'Users',
  components: {
  },
  data () {
    return {
      loading: false,
      message: null,
      error: null,
      users: [],
      lastEvaluatedKey: null,
      readRowLimit: 1000,
      filteredUsers: [],
      meetings: [ 1, 2, 3, 4, 5, 6, 7, 8, 9 ],
      table: {
        fields: [
          // { key: 'consentId', label: 'ID', sortable: true, sortDirection: 'desc', visible: false },
          { key: 'consentNo', label: '同意番号', sortable: true, sortDirection: 'desc', variant: 'primary', stickyColumn: true },
          { key: 'name', label: '名前', sortable: true, class: 'text-nowrap font-weight-bold', stickyColumn: true },
          { key: 'nameKana', label: 'カナ', sortable: true, class: 'text-nowrap' },
          { key: 'email', label: 'email', sortable: true },
          { key: 'meetingId', label: '説明会', variant: 'success', sortable: true, class: 'text-center' },
          { key: 'userNo', label: '参加者No', variant: 'success', sortable: true, class: 'text-center' },
          { key: 'groupId', label: 'グループ', sortable: true, variant: 'warning', class: 'text-center' },
          { key: 'gMeetId', label: 'GMeetスクリーン名', variant: 'success', sortable: true, class: 'text-nowrap' },
          { key: 'minchalleName', label: 'みんチャレ名', variant: 'success', class: 'text-nowrap' },
          { key: 'smartPhoneType', label: 'スマホ', variant: 'success', class: 'text-center' },
          { key: 'minchalleApp', label: 'みんチャレApp', variant: 'success', class: 'text-center' },
          { key: 'healthFitApp', label: '歩数App', variant: 'success', class: 'text-center' },
          { key: 'status', label: 'STATUS', class: 'text-center' },
          { key: 'createdAt', label: '登録日時', sortable: true },
          { key: 'lastModifiedAt', label: '更新日時' }
        ],
        sortBy: 'createdAt',
        sortDesc: true,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        filteringMeeting: null,
        invisibleItems: [ 'email', 'lastModifiedAt' ],
        invisibleItemOptions: [
          { text: 'カナ', value: 'nameKana' },
          { text: 'email', value: 'email' },
          { text: '更新日時', value: 'lastModifiedAt' }
        ]
      },
      editing: {},
      editingItem: null,
      editForm: {
        meetingId: null,
        groupId: null,
        status: null,
        smartPhoneType: null,
        statusOptions: [
          { value: 'YET', text: '未定' },
          { value: 'VALID', text: '有効' },
          { value: 'FIXED', text: '完了' },
          { value: 'INVALID', text: '無効' },
          { value: 'DELETED', text: '削除' }
        ],
        groupOptions: [
          { value: 'YET', text: '未定' },
          { value: 'MINCHALLE', text: 'A:習慣化アプリ' },
          { value: 'GFIT', text: 'B:歩数アプリ' }
        ],
        smartPhoneTypeOptions: [
          { value: 'iPhone', text: 'iPhone' },
          { value: 'Android', text: 'Android' },
          { value: '?', text: '??' }
        ]
      }
    }
  },
  async mounted () {
    await this.loadItems()
  },
  computed: {
    visibleTableFields() {
      return this.table.fields.filter(field => !this.table.invisibleItems.includes(field.key))
    }
  },
  methods: {
    tableRowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.status === 'FIXED') return 'table-success'
      else if ([ 'DELETED', 'INVALID' ].includes(item.status)) return 'table-secondary'
    },
    formatGroup (v) {
      return (GROUPS[v] && GROUPS[v].name) || v
    },
    groupVariant (v) {
      return GROUPS[v] && GROUPS[v].variant
    },
    formatMeeting () {
      return null
    },
    formatStatus (v) {
      return (STATUS[v] && STATUS[v].name) || v
    },
    statusVariant (v) {
      return STATUS[v] && STATUS[v].variant
    },
    formatDate (time, def) {
      return time ? moment(time, 'x').format('MM/DD HH:mm') : (def || '--')
    },
    async loadItems () {
      if (this.loading) return
      this.loading = true
      this.message = null
      this.error = null
      try {
        const res = await this.$store.dispatch('fetchConsents', { limit: this.readRowLimit })
        this.users = res.Items
        this.lastEvaluatedKey = res.lastEvaluatedKey

        while (this.lastEvaluatedKey) {
          const res2 = await this.$store.dispatch('fetchConsents',
            { limit: this.readRowLimit, lastEvaluatedKey: this.lastEvaluatedKey }
          )
          this.users = this.users.concat(res2.Items || [])
          this.lastEvaluatedKey = res.lastEvaluatedKey
        }

        this.filteredUsers = this.filterUsers()
      } catch (err) {
        logger.error(err)
        this.error = err.message || err
      }
      this.loading = false
    },
    async reloadItems () {
      await this.loadItems()
    },
    filterUsers () {
      const f2 = (u) => {
        return !this.table.filteringMeeting || u.meetingId === String(this.table.filteringMeeting)
      }
      return (this.users || []).filter(f2)
    },
    onFilterChanged () {
      this.filteredUsers = this.filterUsers()
    },
    isEditing (row, item) {
      return this.editing && this.editing.consentId === row.consentId && this.editingItem === item
    },
    onStartEditing (data, item) {
      if ([ 'FIXED', 'INVALID', 'DELETED' ].includes(data.status)) return
      this.editing = data
      this.editingItem = item
      this.editForm[item] = this.editing[item]
    },
    validateForEdit () {
      return true
    },
    async onEdit () {
      if (this.loading) return
      if (!this.validateForEdit()) return
      if (this.editForm[this.editingItem] === this.editing[this.editingItem]) {
        logger.debug('not change item:', this.editingItem, this.editForm[this.editingItem], this.editing[this.editingItem])
        this.editing = {}
        this.editingItem = null
        return
      }
      this.message = null
      this.error = null
      this.loading = true
      try {
        await this.editItem(this.editing, this.editingItem, this.editForm[this.editingItem])
        this.message = '更新しました'
      } catch (error) {
        logger.error(error)
        this.error = error
        this.loading = false
        return
      }
      this.editing = {}
      this.editingItem = null
      this.loading = false
      await this.loadItems()
    },
    async editItem (user, name, value) {
      const consentId = user.consentId
      const data = await this.$store.dispatch('editConsent', { consentId, name, value })
      logger.debug(data)
      return data
    },
    async onCheck (user, name, value) {
      if ([ 'FIXED', 'INVALID', 'DELETED' ].includes(user.status)) return
      await this.editItem(user, name, value)
      await this.loadItems()
    },
    openStatusEditModal (user) {
      this.editing = user
      this.editingItem = 'status'
      this.editForm.status = user.status
      this.$refs.statusEditModal.show()
    },
    async onStatusEdit () {
      await this.onEdit()
      this.$refs.statusEditModal.hide()
    },
    download () {
      this.users.forEach(u => delete u['__context__'])
      const csv = utils.json2csv(this.users)
      logger.debug(csv)
      let bom = new Uint8Array([0xEF, 0xBB, 0xBF])
      let blob = new Blob([ bom, csv ], { 'type': 'text/csv' })
      // logger.debug(content)

      let time = moment().format('YYYYMMDDHHmmss')

      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `consents_${time}.txt`
      link.click()
    }
  }
}
</script>

<style scoped>
</style>
